import axios from "axios";
import { useAccount } from "wagmi";
import { localhost_api } from "./config";
import { checkUser, signClaimMessage } from "./getWeb3";
import { toast } from "react-hot-toast";

export const getRoi = async (address, duration, plan) => {
  try {
    const res = await axios.get(localhost_api + "get-roi", {
      params: {
        user: address,
        duration: duration,
        planId: plan,
      },
    });

    return res;
  } catch (error) {
    console.log(error);
  }
};

export const getreward = async (address) => {
  const sign = await signClaimMessage(address);
  if (sign) {
    const res = await axios.post(localhost_api + "claim-reward", {
      user: address,
      amount: 0,
      signature: sign,
      message: address,
    });
    return res;
  } else {
    toast.error("Signature failed");
    return false;
  }

  // console.log(res, ";;;");
};

export const getUserStake = async (address) => {
  try {
    const res = await axios.post(localhost_api + "all-plans-stake", {
      user: address,
    });

    return res;
  } catch (e) {}
};

export const redirectUser = async (user) => {
  try {
    // toast.success("Please Wait We are Authorizing You");
    if (!user) {
      return toast.error("Please connect your wallet");
    }
    console.log("in res1 1");
    const res = await checkUser(user);
    if (!res) {
      return toast.error("User not registered");
    }
    toast.success("Please Wait We are Authorizing You", {
      duration: 5000,
    });
    console.log("in res1 2");
    const token =
      "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpZCI6IjEyIiwiZmlyc3RfbmFtZSI6IkFyY2hpdCIsImxhc3RfbmFtZSI6ImEiLCJkb2IiOiIxNzI2NTExNDAwMDAwIiwiZ2VuZGVyIjoiTWFsZSIsInByb2ZpbGVfcGljIjoibWVkaWEvdXNlcl9wcm9maWxlXzE3MjY5NTA4NjAuanBlZyIsImpvaW5lZF9vbiI6IjE3MjY5NTY1NjcwMDAiLCJsZWFkX3N0YXR1cyI6IiAiLCJ1c2VyX3N0YXR1cyI6ImNsb3NlZC13b24iLCJyZWZlcmVuY2UiOiIwIiwib3duZXIiOiIxIiwicmVtYXJrcyI6InJlbWFya3MiLCJwaG9uZSI6IjkxMjI2Nzg4NDQiLCJlbWFpbCI6ImVtYWlsQGdtYWlsLmNvbSIsInN0YXRlIjoiUmFqYXN0aGFuIiwiYXJlYSI6IkRvbnQgUm9hZCIsImNpdHkiOiJKYWlwdXIiLCJwaW5jb2RlIjoiMzAyMDE4IiwiaW50ZXJlc3QtcHJvdG9jb2wiOiIxIiwiZGlzcG9zaXRpb24iOiIxMiIsImFtb3VudCI6IjEyMzQ1Iiwid2FsbGV0X2FkZHJlc3MiOiIiLCJtYXN0ZXJfcmVmZXJlbmNlX2lkIjoiIiwibWFzdGVyX3VzZXJfaWQiOiJXWVNDVkFfVSIsImV4cGlyeSI6MTczMTU3MzE3M30.3hf3ThxB24j6Xfu5_oq0xlK_1KWcuUCiamrFvW4oDRU";
    if (res) {
      console.log("in res1 3");
      const resApi = await axios.put(
        "https://dms.wyscale.com:3000/api/v1/auth",
        {
          user: user,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        }
      );
      // console.log(resApi, resApi?.data?.payload?.JWT_Token, "::::resApi");
      const vToken = resApi?.data?.payload?.JWT_Token;
      console.log(resApi.data.payload?.JWT_Token, "::::123");
      if (vToken) {
        window.location.href = "https://dms.wyscale.com/?authToken=" + vToken;
      } else {
        toast.error("User not verified");
      }
    }
  } catch (error) {
    console.log(error);
    toast.error("User not verified");
  }
};