import React, { useEffect, useState } from "react";
import Header from "./Header";
import Footer from "./Footer";
import Dchart from "./Dchart";
import { localhost_api } from "./web3/config";
import { useAccount } from "wagmi";
import axios from "axios";
import Dchart1 from "./Dchart1";
import { useAsyncError } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";
import { PieChart } from "@mui/x-charts/PieChart";
import { useSelector } from "react-redux";
import { redirectUser } from "./web3/api";
// import { PieChart } from '@mui/x-charts';

const WyscaleD = () => {
  const [teamCount, setTeamCount] = useState();
  const [dashboard, setDashboard] = useState();
  const { address } = useAccount();
  // const address = "0x8fc4966d2Eb3aD74362d3c487c66b63eA091349A";

  const [insuranceData, setInsuranceData] = useState();
  const [stakingAmt, setStakingAmt] = useState();
  const [stakingTeam, setStakingTeam] = useState();
  const [userName, setUserName] = useState();
  const [userId, setUserId] = useState();

  const getUserName = async () => {
    try {
      const res = await axios.get("https://farming.wyscale.com/api/alluser", {
        params: {
          page: 1,
          search: address,
        },
      });
      if (res.status == 200) {
        setUserName(res.data.usersData[0].name);
        console.log(res.data.usersData[0].name, ":::");
      } else {
        setUserName(false);
      }
    } catch (error) {
      console.log(error);
      setUserName(false);
    }
  };

  const fetchTeamData = async (page) => {
    try {
      const response = await axios.get(localhost_api + "team-list", {
        params: {
          user: address,
          page: 1,
          limit: 10,
        },
      });
      if (response.data.data != undefined) {
        const totalItems = response.data.totalDataCount;
        setTeamCount(totalItems);
      }
    } catch (error) {
      console.log("Error fetching data:", error);
    }
  };

  const dashboardData = async () => {
    try {
      const response = await axios.get(localhost_api + "dashboard", {
        params: {
          user: address,
        },
      });

      if (response) {
        setDashboard(response.data.data);
      }
      console.log(response, ":::::1234");
    } catch (e) {
      console.log(e);
    }
  };

  const getLastPurchaseanddetails = async () => {
    try {
      const res = await axios.get(
        "https://insurance.wyscale.com/api/user-data",
        {
          params: {
            search: address,
          },
        }
      );

      if (res.status == 200) {
        setInsuranceData(res?.data?.data[0]);
      } else {
      }
    } catch (err) {
      console.log(err);
    }
  };

  async function getDashboardData1() {
    try {
      const res = await axios.post(
        "https://stake.wyscale.com/api/total-staking",
        {
          walletAddress: address,
        }
      );
      setStakingAmt(res?.data?.sumOfAmount);
    } catch (error) {
      console.log(error, "::::::");
    }
  }

  async function getStakingTeam() {
    try {
      const res = await axios.get("https://stake.wyscale.com/api/team-list", {
        params: {
          user: address,
          limit: 100,
          page: 1,
          sortField1: "level",
          sortField2: null,
        },
      });
      if (res.status == 200) {
        setStakingTeam(res.data.totalDataCount);
      }
    } catch (error) {
      console.log(error);
    }
  }

  const copyText = async (textToCopy) => {
    if (navigator.clipboard && window.isSecureContext) {
      await navigator.clipboard.writeText(textToCopy);
      toast.success("Referral Copied");
    } else {
      const textArea = document.createElement("textarea");
      textArea.value = textToCopy;
      textArea.style.position = "absolute";
      textArea.style.left = "-999999px";

      document.body.prepend(textArea);
      textArea.select();

      try {
        document.execCommand("copy");
        toast.success("Referral Copied");
      } catch (error) {
        console.error(error);
      } finally {
        textArea.remove();
      }
    }
  };

  const handleCopyClick = (dynamicText) => {
    copyText(dynamicText);
  };

  const traderApi = async () => {
    try {
      if (!address) {
        toast.error("Plese connect wallet");
        return;
      }

      if (!userName) {
        toast.error("First register your self");
        return;
      }

      window.open(
        `https://trader.wyscale.com/m/1j.aspx?uid=${dashboard.userId}&wallet=${dashboard.user}`,
        "_self"
      );
    } catch (error) {
      console.log(error, "from traderApi");
    }
  };

  useEffect(() => {
    if (address) {
      fetchTeamData();
      dashboardData();
      getLastPurchaseanddetails();
      getDashboardData1();
      getStakingTeam();
      getUserName();
    }
  }, [address]);

  return (
    <>
      <Header />
      <Toaster />
      {(!userName || !dashboard?.userId) && (
        <div className="lDiv gap-2">
          <div class="loader"></div>
          <div
            className="text-white"
            style={{
              background: "linear-gradient(45deg, #8fd41f 0%, #ffe569 40%)",
              WebkitBackgroundClip: "text",
              WebkitTextFillColor: "transparent",
              fontSize: "25px",
            }}
          >
            {address ? "Loading..." : "Please Connect Wallet"}
          </div>
        </div>
      )}
      <div
        className="row col-lg-12 dashMainDiv"
        style={{
          background: "url(image/banner.png)",
          minHeight: "600px",
          justifyContent: "space-evenly ",
        }}
      >
        <div
          className="col-lg-3 webDiv position-relative"
          style={{ marginLeft: "55px" }}
        >
          <div className="guide">Your Web3 De-Fi Guide</div>
          <div className="e">Explore</div>
          <div className="wys">Web3</div>
          <div className="d">Dashboard</div>
          {dashboard?.userId && (
            <div className="row col-xxl-12 col-xl-12 col-lg-12 userImg">
              <img
                src="image/user.png"
                alt=""
                style={{ height: "55px", width: "70px" }}
              />
              <div className="row userName">
                <h5 style={{ fontSize: "16px", padding: "0px" }}>
                  {userName?.length > 10
                    ? userName?.split(" ")[0].toUpperCase()
                    : userName?.slice(0, 9).toUpperCase()}
                </h5>
                <h6
                  style={{
                    fontSize: "16px",
                    padding: "0px",
                    cursor: "pointer",
                  }}
                  onClick={() =>
                    handleCopyClick(
                      `https://registration.wyscale.com?refID=${dashboard?.userId}`
                    )
                  }
                >
                  https://....?refID={dashboard?.userId}{" "}
                  <i className="fa fa-copy" style={{ paddingLeft: "2px" }}></i>
                </h6>
              </div>
            </div>
          )}
        </div>
        <div className="row col-lg-10 col-xl-8 col-xxl-8 dash">
          <div
            className="row col-lg-5"
            style={{ flexDirection: "column", cursor: "pointer" }}
            onClick={() => {
              window.open("https://farming.wyscale.com/dashboard", "_self");
            }}
          >
            <div className="row col-lg-12 d1">
              <a href="https://farming.wyscale.com/dashboard" target="_self">
                Farming
              </a>
              <span>
                <i
                  class="fa-solid fa-arrow-up"
                  style={{ color: "white", rotate: "33deg", fontSize: "18px" }}
                ></i>
              </span>
            </div>
            <div
              className="row col-lg-12 investment"
              style={{ margin: "5px auto 1px", flexDirection: "column" }}
            >
              <span
                style={{
                  height: "1px",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                {dashboard ? (dashboard?.wysStaked / 1e18).toFixed(2) : "0"}
              </span>
              <br />
              <span
                style={{
                  // fontSize: "14px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <img
                  src="https://s2.coinmarketcap.com/static/img/coins/64x64/12384.png"
                  alt=""
                  style={{ height: "20px", width: "20px", borderRadius: "50%" }}
                />
              </span>
            </div>
            <div
              className="row col-lg-10 dash-ref"
              style={{ justifyContent: "space-between" }}
            >
              <span style={{ width: "fit-content", padding: "0px" }}>
                Referrals
              </span>
              <span style={{ width: "fit-content", padding: "0px" }}>
                {teamCount > 0 ? teamCount : 0}
              </span>
            </div>
          </div>
          <div
            className="row col-lg-5"
            style={{ flexDirection: "column", cursor: "pointer" }}
            onClick={() => {
              window.open("https://insurance.wyscale.com/", "_self");
            }}
          >
            <div className="row col-lg-12 d2">
              <a
                href="https://insurance.wyscale.com/"
                target="_self"
                style={{ fontSize: "17px" }}
              >
                Insurance
              </a>
              <span style={{ background: "white" }}>
                <i
                  class="fa-solid fa-arrow-up"
                  style={{ color: "black", rotate: "33deg", fontSize: "18px" }}
                ></i>
              </span>
            </div>
            <div className="insInvestment row col-lg-8">
              <img
                src="https://insurance.wyscale.com/assets/ethereumstake-XFPHVyHx.png"
                alt=""
                style={{
                  height: "27px",
                  width: "40px",
                  position: "relative",
                }}
              />
              {insuranceData && insuranceData?.shareprice > 0
                ? (insuranceData?.shareprice / 1e18)?.toFixed(5)
                : 0}
            </div>
            <div
              className="row col-lg-11"
              style={{
                margin: "60px auto 1px",
                color: "white",
                fontSize: "28px",
                position: "relative",
                left: "10px",
              }}
            >
              {insuranceData && insuranceData?.totalShare > 0
                ? insuranceData?.totalShare
                : 0}{" "}
              Unit
            </div>
          </div>
          <div
            className="row col-lg-5 "
            onClick={() => {
              window.open("https://staking.wyscale.com/usdt/Usdt", "_self");
            }}
            style={{ cursor: "pointer" }}
          >
            <div className="row col-lg-12 d3">
              <a href="https://staking.wyscale.com/usdt/Usdt" target="_self">
                Staking
              </a>
              <span style={{ background: "#37373f" }}>
                <i
                  class="fa-solid fa-arrow-up"
                  style={{ color: "white", rotate: "33deg", fontSize: "18px" }}
                ></i>
              </span>
            </div>
            <div
              className="row col-lg-12"
              style={{ color: "white", fontSize: "40px", paddingLeft: "19px" }}
            >
              $ {stakingAmt > 0 ? Number(stakingAmt)?.toFixed(2) : 0}
            </div>
            {/* <div className="col-lg-12">
              <Dchart />
            </div> */}
            <div
              className="row col-lg-10 dash-ref"
              style={{
                justifyContent: "space-between",
                borderTop: "1px solid white",
              }}
            >
              <span
                style={{ width: "fit-content", padding: "0px", color: "white" }}
              >
                Referrals
              </span>
              <span
                style={{ width: "fit-content", padding: "0px", color: "white" }}
              >
                {stakingTeam > 0 ? stakingTeam : 0}
              </span>
            </div>
          </div>
          {/* gleamStar */}
          <div
            className="row col-lg-5 "
            onClick={() => {
              window.open("https://staking.wyscale.com/usdt/Usdt", "_self");
            }}
            style={{ cursor: "pointer" }}
          >
            <div className="row col-lg-12 d6" style={{ borderColor: "white" }}>
              <a
                href="https://gleam.wyscale.com/home/"
                target="_self"
                style={{ color: "white" }}
              >
                Gleam
              </a>
              <span style={{ background: "white" }}>
                <i
                  class="fa-solid fa-arrow-up"
                  style={{ color: "black", rotate: "33deg", fontSize: "18px" }}
                ></i>
              </span>
            </div>
            <div
              className="row col-lg-12"
              style={{ color: "white", fontSize: "40px" }}
            >
              <img
                src="https://gleam.wyscale.com/images/pie-chart.png"
                alt=""
                style={{ height: "100px" }}
              />
            </div>
          </div>
          {/* trader */}

          <div
            className="row col-lg-5 "
            onClick={() => {
              window.open("http://fx.wyscale.com/site/index.html", "_self");
            }}
            style={{ cursor: "pointer" }}
          >
            <div className="row col-lg-12 d6" style={{ borderColor: "white" }}>
              <a
                href="http://trader.wyscale.com/site/index.html"
                target="_self"
                style={{ color: "white" }}
              >
                Broker
              </a>
              <span style={{ background: "#37373f" }}>
                <i
                  class="fa-solid fa-arrow-up"
                  style={{ color: "white", rotate: "33deg", fontSize: "18px" }}
                ></i>
              </span>
            </div>
            <div
              className="row col-lg-12"
              style={{
                color: "white",
                fontSize: "40px",
                position: "relative",
                // top: "-15px",
                // left: "-22px",
              }}
            >
              <img src="/image/b.png" alt="" style={{ height: "160px" }} />
            </div>
          </div>

          <div
            className="row col-lg-5 "
            onClick={() => {
              // window.open("https://staking.wyscale.com/usdt/Usdt", "_self");
              traderApi();
            }}
            style={{ cursor: "pointer" }}
          >
            <div className="row col-lg-12 d6" style={{ borderColor: "white" }}>
              <a
                href="javascript:(void:0)"
                target="_self"
                style={{ color: "white" }}
              >
                Trader
              </a>
              <span style={{ background: "white" }}>
                <i
                  class="fa-solid fa-arrow-up"
                  style={{ color: "black", rotate: "33deg", fontSize: "18px" }}
                ></i>
              </span>
            </div>
            <div
              className="row col-lg-12"
              style={{ color: "white", fontSize: "40px" }}
            >
              <img
                src="/image/l.png"
                alt=""
                // style={{ height: "100px" }}
              />
            </div>
          </div>

          {/* broker */}

          <div
            className="row col-lg-5"
            onClick={() => {
              window.open(
                "https://staking.wyscale.com/staking/dashboard",
                "_self"
              );
            }}
          >
            {" "}
            <div className="row col-lg-12 d4">
              <a href="javascript:(void:0)" target="_self">
                Alt Staking
              </a>
              <span style={{ marginLeft: "1px", background: "#37373f" }}>
                <i
                  class="fa-solid fa-arrow-up"
                  style={{ color: "white", rotate: "33deg", fontSize: "18px" }}
                ></i>
              </span>
            </div>
            <div className="col-lg-12">
              <Dchart1 />
            </div>
          </div>

          <div
            className="row col-lg-5"
            onClick={() => {
              window.open("https://rwa.wyscale.com/explore", "_self");
            }}
            style={{
              background: 'url("/image/rwa.png")',
              backgroundSize: "cover",
              backgroundPosition: "left -85px top 16px",
            }}
          >
            {" "}
            <div className="row col-lg-12 d4 d5">
              <a
                href="javascript:(void:0)"
                target="_self"
                style={{ color: "white", border: "1px solid white" }}
              >
                RWA
              </a>
              <span style={{ marginLeft: "1px", background: "white" }}>
                <i
                  class="fa-solid fa-arrow-up"
                  style={{
                    color: "#37373f",
                    rotate: "33deg",
                    fontSize: "18px",
                  }}
                ></i>
              </span>
            </div>
            <div
              className="col-lg-12"
              style={{ position: "relative", top: "-15px", left: "-22px" }}
            >
              {/* <img src="/image/rwa.png" alt="" height={100}/> */}
            </div>
          </div>

          <div
            className="row col-lg-5"
            onClick={() => {
              window.open("https://legacy.wyscale.com/", "_self");
            }}
          >
            {" "}
            <div className="row col-lg-12 d4 d5">
              <a
                href="javascript:(void:0)"
                target="_self"
                style={{ color: "white", border: "1px solid white" }}
              >
                Legacy
              </a>
              <span style={{ marginLeft: "1px" }}>
                <i
                  class="fa-solid fa-arrow-up"
                  style={{ color: "white", rotate: "33deg", fontSize: "18px" }}
                ></i>
              </span>
            </div>
            <div
              className="col-lg-12"
              style={{ position: "relative", top: "-15px", left: "-22px" }}
            >
              <PieChart
                series={[
                  {
                    data: [
                      { name: "Category A", value: 30, color: "#febf4b" },
                      { name: "Category B", value: 20, color: "#37373f" },
                      { name: "Category C", value: 50, color: "#ccdd47" },
                    ],
                    innerRadius: 30,
                    outerRadius: 100,
                    paddingAngle: 5,
                    cornerRadius: 5,
                    startAngle: -90,
                    endAngle: 180,
                    cx: 150,
                    cy: 150,
                  },
                ]}
              />
            </div>
          </div>

          <div
            className="row col-lg-5"
            onClick={() => {
              redirectUser(address);
            }}
            style={{cursor:'pointer'}}
          >
            {" "}
            <div className="row col-lg-12 d4 d5">
              <a
                href="javascript:(void:0)"
                target="_self"
                style={{ color: "white", border: "1px solid white" }}
              >
                DMS
              </a>
              <span style={{ marginLeft: "1px",background:'white' }}>
                <i
                  class="fa-solid fa-arrow-up"
                  style={{ color: "black", rotate: "33deg", fontSize: "18px" }}
                ></i>
              </span>
            </div>
            <div
              className="col-lg-12"
              style={{ position: "relative", top: "-15px", left: "-22px" }}
            >
              <PieChart
                series={[
                  {
                    data: [
                      { name: "Category A", value: 30, color: "#ccdd47" },
                      { name: "Category B", value: 20, color: "#febf4b" },
                      { name: "Category C", value: 50, color: "#ffebf4b" },
                    ],
                    innerRadius: 30,
                    outerRadius: 100,
                    paddingAngle: 5,
                    cornerRadius: 5,
                    startAngle: -90,
                    endAngle: 180,
                    cx: 150,
                    cy: 150,
                  },
                ]}
              />
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default WyscaleD;
