import React from 'react';
import { Sparklines, SparklinesCurve } from 'react-sparklines';

const sampleData = [5, 10, 5, 20, 8, 15, 10, 20];

const SparklinesChart = () => {
  return (
    <div style={{ width: '100%' }}>
      <Sparklines data={sampleData}>
        <SparklinesCurve color="yellow" style={{ fill: "none", strokeWidth: 2 }} />
      </Sparklines>
    </div>
  );
};

export default SparklinesChart;
